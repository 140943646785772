import React from 'react';
import styled from 'styled-components';
import { Title, Text } from '../../components/Core';
import downArrow from './../../assets/image/png/down-arrow.png'
import checkedArrow from './../../assets/image/png/checked-arrow.png'

// Define the container for the entire component
const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProgressWrapper = styled.div`
  background-color: #fff;
  border-radius: 50px;
  padding: 20px;
`

// Define the styles for each circle
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ active }) => active ? "60px" : "40px"};
  height: ${({ active }) => active ? "60px" : "40px"};
  border-radius: 50%;
  border: ${({ active, completed }) => active ? "3px solid #c5007e" : "none"};
  background: ${({ active, completed }) =>
    active
      ? '#fff'
      : completed
      ? 'linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%)'
      : '#ccc'};
  color: white;
  font-weight: bold;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: ${({ completed }) => (completed ? '100% !important' : '100% !important')};
    width: ${({ completed }) => (completed ? '190px' : '190px')};
    height: 4px;
    background: ${({ completed }) => (completed ? 'linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%)' : '#ccc')};
    transform: translateY(-50%);
    z-index: -1;
    ${({ isLast }) =>
      isLast &&
      `
      display: none;
    `}

    @media (max-width: 991px) {
      width: ${({ completed }) => (completed ? '186px' : '137px')};
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  &:last-child:after {
    display: none;
  }
`;

// Define the wrapper for each step, including the circle and label
const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: left;
    width: 100%;
    margin-bottom: 0;

    ${Circle} {
      margin-right: ${({ active }) => active ? "20px" : "40px"};
    }
  }
`;

const steps = [
  { label: 'Not<br/>planned' },
  { label: 'Planning<br/>stage' },
  { label: 'Building<br/>stage' },
  { label: 'Ready for<br/>service' }
];

const RightArrow = styled.img`
  width: 20px;
  transform: rotate(270deg);
  position: relative;
  left: 2px;
`

const ProgressBar = ({ selectedStep, title, subtitle }) => {
  return (
    <ProgressWrapper>
      <Title variant="news" className="text-center pt-5">{title}</Title>
      <ProgressContainer>
        {steps.map((step, index) => {
          const isActive = index === selectedStep;
          const isCompleted = index < selectedStep;
          const isLast = index === steps.length - 1;

          return (
            <StepWrapper key={index} active={isActive}>
              <Circle active={isActive} completed={isCompleted} isLast={isLast}>
                {isCompleted ? '✓' : isActive ? <img src={checkedArrow} width={35}/> : <RightArrow src={downArrow} />}
              </Circle>
              <Title
                className={isActive ? "text-center" : "pr-t10 text-center"}
                variant="card"
                mb="12px"
                mt="20px"
                dangerouslySetInnerHTML={{ __html: step.label }}
              ></Title>
              <br></br>
            </StepWrapper>
          );
        })}
      </ProgressContainer>
      <Text className="text-center pb-5">{subtitle}</Text>
    </ProgressWrapper>
  );
};

export default ProgressBar;